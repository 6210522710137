import Select, {components} from 'react-select';
import {DropdownIndicatorIcon} from "../../atoms/icons/dropdownIndicatorIcon";
import Image from "next/image";
import {FC, useEffect} from "react";
import {Paragraph} from "../../atoms/basics/paragraph";
import IdealIcon from "../../atoms/icons/iDealIcon";

const customStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        border: '2px solid #0ead69',
        borderRadius: '.5rem',
        paddingRight: '.5rem',
        boxShadow: 'none',
        color: '#2b2b2b',
        "&:hover": {
            outline: 'none'
        },
        "&:focus": {
            outline: 'none'
        }
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        display: 'inline-flex'
    })
}

const CustomOption = (props: any) => {
    return (
        <components.Option {...props} className={'inline-flex gap-2'}>
            <Image src={props.data.image} alt={`${props.data.label} logo`} priority={true} width={32} height={24}/>
            {props.data.label}
        </components.Option>
    )
};

interface PaymentSelectProps {
    valueChange: (value: string) => void
}

export const PaymentSelect: FC<PaymentSelectProps> = ({valueChange}) => {
    useEffect(() => {
        valueChange("hello_ideal")
    }, [])

    return (
        <div className={'w-full -mt-4 flex gap-2 items-center'}>
            <IdealIcon className={'flex-none'}/>
            <Paragraph className={'text-sm italic'}>Betaal op de volgende pagina eenvoudig met iDEAL</Paragraph>
        </div>
    )
}