import * as React from "react"

function IdealIcon(props: {[x: string]: unknown}) {
    return (
        <svg
            width={64}
            height={48}
            viewBox="0 0 64 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect
                width={64}
                height={48}
                rx={8}
                fill="color(display-p3 .902 .3922 .6471)"
            />
            <path
                d="M15 11.225v25.55c0 1.228.995 2.225 2.222 2.225h15.253C44.002 39 49 32.538 49 23.97 49 15.436 44.002 9 32.475 9H17.22C15.997 9 15 9.995 15 11.225z"
                fill="#fff"
            />
            <path
                d="M32.471 36.954H19.207a2.123 2.123 0 01-1.505-.622c-.4-.4-.627-.943-.63-1.51V13.178a2.153 2.153 0 01.627-1.514 2.13 2.13 0 011.507-.625h13.265c12.582 0 14.461 8.1 14.461 12.93 0 8.375-5.135 12.985-14.46 12.985zM19.207 11.756c-.376 0-.737.149-1.004.415a1.434 1.434 0 00-.42 1.007v21.646c0 .785.637 1.422 1.423 1.422h13.265c8.867 0 13.75-4.36 13.75-12.274 0-10.63-8.614-12.216-13.75-12.216H19.207z"
                fill="#000"
            />
            <path
                d="M25.205 15.273v18.876h8.21c7.457 0 10.689-4.22 10.689-10.169 0-5.7-3.232-10.129-10.69-10.129h-6.788c-.375 0-.736.15-1.002.415a1.432 1.432 0 00-.419 1.007z"
                fill="color(display-p3 .8 0 .4)"
            />
            <path
                d="M40.854 21.432v3.675h2.195v.835h-3.184V21.43l.989.002zm-3.297 0l1.685 4.51h-1.028l-.34-1.002h-1.687l-.352 1.002h-1.001l1.705-4.512 1.018.002zm.057 2.765l-.569-1.654h-.012l-.586 1.654h1.167zm-3.45-2.765v.832h-2.38v.969h2.183V24h-2.182v1.106h2.43v.835h-3.42V21.43l3.369.002zm-6.07 0c.277-.002.553.043.811.138a1.765 1.765 0 011.08 1.113c.197.612.207 1.269.028 1.887-.076.264-.206.509-.38.72a1.792 1.792 0 01-.64.476c-.283.123-.59.182-.898.176H26.15V21.43h1.946l-.002.002zm-.069 3.675c.143 0 .283-.022.418-.07a.92.92 0 00.358-.23c.113-.12.2-.263.254-.419.068-.203.1-.417.094-.632 0-.206-.02-.412-.065-.616a1.224 1.224 0 00-.224-.47.971.971 0 00-.4-.3 1.587 1.587 0 00-.615-.104h-.707v2.841h.887z"
                fill="#fff"
            />
            <path
                d="M21.495 26.963c.87 0 1.575.705 1.575 1.574v5.612a3.15 3.15 0 01-3.15-3.152v-2.46a1.58 1.58 0 01.462-1.118 1.563 1.563 0 011.115-.456h-.002zM21.496 25.767a2.08 2.08 0 002.078-2.081 2.08 2.08 0 10-2.078 2.08z"
                fill="#000"
            />
        </svg>
    )
}

export default IdealIcon;
